<template>
    <el-dialog
      :title="dialogType === 'edit' ? '修改采集任务' : '新建采集任务'"
      :visible.sync="dialogFormVisible"
      append-to-body
      :close-on-click-modal="false"
      @close="$emit('update:dialogVisible', false)"
      width="65%"
    >
    <div class="view-dialog">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="150px"
      >
        <div class="form-lable">基本信息</div>
        <el-form-item label="任务名称" prop="taskName">
          <el-input
            v-model="ruleForm.taskName"
            placeholder="请填写任务名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item  label="机构名称" prop="sitesOrgArr">
          <el-cascader
            v-model="ruleForm.sitesOrgArr"
            :options="orgTreeData"
            clearable
            :props="{
                expandTrigger: 'hover',
                label: 'agencyName',
                value: 'agencyName',
                checkStrictly: true,
              }"
            ref="sitesOrg"
            @change="changeSite($event)"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="监测主体" prop="grabType">
          <el-select
            v-model="ruleForm.grabType"
            clearable
            placeholder="请选择监测主体"
          >
            <el-option
              v-for="item in grabTypes"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.grabType == 0"
          label="网站链接"
          prop="agencyHomeUrl"
        >
          <el-input
            v-model="ruleForm.agencyHomeUrl"
            placeholder="请填写网站链接"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item v-else label="公众号" prop="agencyHomeUrl">
          <el-input
            v-model="ruleForm.agencyHomeUrl"
            placeholder="请填写公众号"
            clearable
          ></el-input>
        </el-form-item>
        <div class="form-lable"> <div>采集范围</div>
           <el-upload ref="upload" action="/" :show-file-list="false" :on-change="importExcel" :auto-upload="false" v-if="dialogType === 'add'">
           <el-button class="d2-mt-10 d2-mb-10" slot="trigger" icon="el-icon-upload" type="primary" style="margin-right: 1rem;">
            导入excel
          </el-button>
        </el-upload>
      </div>
        <el-form-item>
          <el-table
            border
            :data="tableData"
            style="width: 100%"
          >
          <el-table-column type="index" label="序号"> </el-table-column>
            <el-table-column prop="channelName" >
              <template slot="header">
                <span class="red">* &nbsp;</span>
                <span>模块名称</span>
              </template>
              <template slot-scope="scope">
                <el-input
                  style="width: 100%"
                  v-model="scope.row.channelName"
                  placeholder="请输入模块名称"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="channelUrl" width="450">
              <template slot="header">
                <span class="red">* &nbsp;</span>
                <span>网页地址</span>
              </template>
              <template slot-scope="scope">
                <el-input
                  style="width: 100%"
                  v-model.trim="scope.row.channelUrl"
                  placeholder="请输入链接"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template
                slot-scope="scope"
              >
                <el-button
                  @click.native.prevent="deleteRow(scope.$index, tableData)"
                  type="text"
                  size="small"
                  >移除</el-button
                >
                <el-button
                  @click.native.prevent="addRow(scope.$index, tableData)"
                  type="text"
                  size="small"
                  v-if="scope.$index + 1 === tableData.length"
                  >添加</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="$emit('update:dialogVisible', false)"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="saveOnClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>
</template>

<script>
import XLSX from 'xlsx'
export default {
  name: 'addAcquisition',
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    queryObject: {
      type: Object,
      default: () => ({}),
    },
    dialogType: {
      type: String,
      require,
    },
  },
  data() {
    return {
      dialogFormVisible: this.dialogVisible,
      ruleForm: {
        taskName: '', //任务名称
        agencyId: '', //机构ID
        sitesOrgArr: [], //机构联动
        agencyName: '', //机构名称
        grabType: 0, //订单类别
        agencyHomeUrl: '', //网站URL地址
        channelList: [],
      },
      tableData: [
        {
          channelName: '', //模块名称
          channelUrl: '', //模块网址
        },
      ],
      deleteList: [], //机构下拉
      orgTreeData: [], //
      wb: null,
      //监测主体
      grabTypes: [
        { name: '网站', type: 0 },
        { name: '公众号', type: 1 },
      ],
      rules: {
        taskName: [
          {
            required: true,
            message: '请输入任务名称',
            trigger: 'blur',
          },
        ],
        sitesOrgArr: [
          { required: true, message: '请选择机构名称', trigger: 'blur' },
        ],
        grabType: [
          { required: true, message: '请选择监测主体', trigger: 'change' },
        ],
        agencyHomeUrl: [
          {
            required: true,
            message: '请输入网站链接或微信号',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {},
  mounted() {
    this.loadData();
  },
  methods: {
    // 确定
    saveOnClick() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.ruleForm.channelList = this.tableData;
          let newArr = this.ruleForm.channelList.map((item) => ({
            channelName: item.channelName,
            channelUrl: item.channelUrl,
          }));
          const hasEmptyValue = newArr.some((obj) => {
            return Object.values(obj).some(
              (value) => value === null || value === undefined || value === ''
            );
          });
          if (hasEmptyValue) {
            this.$message.warning('模块名称，模块网址不能为空');
            return
          }
          if (this.dialogType === 'add') {
            this.$api.dataAcquisition
              .addCorrectWord(this.ruleForm)
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("新建成功");
                  this.$emit('update:dialogVisible', false);
                  this.$emit('update');
                }
              });
          } else {
            this.$set(this.ruleForm, 'id', this.queryObject.id);
            delete this.ruleForm.sitesOrgArr;
            this.$api.dataAcquisition
              .editCorrectWord(this.ruleForm)
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.$emit('update:dialogVisible', false);
                  this.$emit('update');
                }
              });
          }
        }
      });
    },
    //加载机构树
    async loadOrgTree() {
      let res = await this.$api.operationOrder.agencyUserTree();
      if (res.data) {
        this.orgTreeData = res.data
      }
    },
    // 选择机构触发事件
    changeSite(e) {
      const curNode = this.$refs['sitesOrg'].getCheckedNodes();
      if (curNode.length !==0) {
        this.ruleForm.agencyName = curNode[0].data.agencyName || '';
        this.ruleForm.agencyId = curNode[0].data.agencyId || '';
      }
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    addRow(index, rows) {
        rows.push({});
    },
    // 机构递归处理找到父节点
    // // parents:用于返回的数组，childNode:要查询的节点，treeData：json树形数据
    // findParent(parents, childNode, treeData) {
    //   for (let i = 0; i < treeData.length; i++) {
    //     // 父节点查询条件
    //     if (treeData[i].id === childNode.pid) {
    //       // 如果找到结果,保存当前节点
    //       parents.push(treeData[i]);
    //       // 用当前节点再去原数据查找当前节点的父节点
    //       this.findParent(parents, treeData[i], this.orgTreeData);
    //       break;
    //     } else {
    //       if (treeData[i].children instanceof Array) {
    //         //	没找到，遍历该节点的子节点
    //         this.findParent(parents, childNode, treeData[i].children);
    //       }
    //     }
    //   }
    //   return parents;
    // },
    //机构树回显
    search(object, value) {
      for (let key in object) {
        if (object[key].agencyName == value) return [object[key].agencyName];
        if (
          object[key].children &&
          Object.keys(object[key].children).length > 0
        ) {
          var temp =this.search(object[key].children, value);
          if (temp) return [object[key].agencyName, temp].flat();
        }
      }
    },
    //修改获取详情
    getDetail(id) {
      this.$api.dataAcquisition.queryById({ id: id }).then(async (res) => {
        if (res.code === 200) {
          for (let key in this.ruleForm) {
            this.ruleForm[key] = res.data.task[key];
          }
          this.tableData = res.data.task.channelList;
         let arr = await this.search(
            this.orgTreeData,
            res.data.task.agencyName
          );
          this.ruleForm.sitesOrgArr =  arr
        }
      });
    },
               importExcel(file) {
                const types = file.name.split(".")[1];
                const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
                  item => item === types
                );
                if (!fileType) {
                   alert("格式错误！请重新选择");
                  return;
                }
                this.file2Xce(file).then(tab => {
                  if (tab && tab.length > 0) {
                    this.tableData = tab;
                    this.$message.success('导入成功')
  
                  }
                });
            },
            file2Xce(file) {
                return new Promise(function(resolve, reject) {
                  const reader = new FileReader();
                  reader.onload = function(e) {
                    const data = e.target.result;
                    this.wb = XLSX.read(data, {
                      type: "binary"
                    });
                    const result = [];
                    const sheet_name_list =  this.wb.SheetNames;
                    const worksheet = this.wb.Sheets[sheet_name_list[0]];
                    const sheet_data = XLSX.utils.sheet_to_json(worksheet, {header: 1});
                    sheet_data.forEach(item => {
                      result.push({
                        channelName: item[0],
                        channelUrl:item[1]
                      });
                    });
                    resolve(result);
                  };
                  reader.readAsBinaryString(file.raw);
                });
        },
    async loadData() {
      //加载机构树
      await this.loadOrgTree();
      //编辑请求详情
      if (this.queryObject.id) {
        await this.getDetail(this.queryObject.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form .el-input {
  width: 70%;
}
.el-form-item .el-select {
  width: 70%;
}
.el-cascader {
  width: 70%;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.form-lable {
  line-height: 40px;
  padding-left: 70px;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-dialog .el-dialog__body {
  padding: 0 15px;
}
.view-dialog {
  max-height: 25rem;
  overflow-y: auto;
}
</style>
