import { render, staticRenderFns } from "./dataAcquisition.vue?vue&type=template&id=4d728dba&scoped=true&"
import script from "./dataAcquisition.vue?vue&type=script&lang=js&"
export * from "./dataAcquisition.vue?vue&type=script&lang=js&"
import style0 from "./dataAcquisition.vue?vue&type=style&index=0&id=4d728dba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d728dba",
  null
  
)

export default component.exports