<template>
  <div class="main">
    <div v-loading="loading" class="main-container">
      <div class="pt-wrapper">
        <!-- 搜索 -->
        <div class="hd-search">
          <table-search
            :model="searchParams"
            label-width="80px"
            :isClear="true"
            :isResetByPublic="false"
            :flex="'6'"
            @search="searchFn"
            @clearSearch="clearSearchFn"
          >
            <search-item default label="">
              <el-input
                size="small"
                v-model="searchParams.agencyName"
                placeholder="请输入机构名称"
                clearable
              >
              </el-input>
            </search-item>
            <search-item default label="">
              <el-select
                v-model="searchParams.grabType"
                placeholder="监测主体"
                clearable
              >
                <el-option
                  v-for="item in grabTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </search-item>

            <search-item>
              <el-select
                v-model="searchParams.status"
                placeholder="采集状态"
                clearable
              >
                <el-option
                  v-for="item in statuses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </search-item>
          </table-search>
        </div>
        <div class="bd-content" style="margin-top: 16px">
          <div class="table-opt">
            <el-button
              type="primary"
              class="el-icon-circle-plus-outline"
              @click="goDetail('add')"
            >
              新建采集任务
            </el-button>
          </div>
          <!-- 表格 -->
          <pageTable
            ref="table"
            :initFalse="true"
            :columns="columnList"
            :params="searchParams"
            :api-function="this.$api.dataAcquisition.queryTaskList"
          >
            <template slot="grabType" slot-scope="scope">
              <div class="data-operate">
                <span v-if="scope.row.grabType === 0">网站</span>
                <span v-if="scope.row.grabType === 1">公众号</span>
              </div>
            </template>
            <template slot="status" slot-scope="scope">
              <div class="data-operate">
                <span v-if="scope.row.status === 0">待采集</span>
                <span v-if="scope.row.status === 1">采集中</span>
                <span v-if="scope.row.status === 2">已完成</span>
                <span v-if="scope.row.status === 3">已拒绝</span>
                <span v-if="scope.row.status === 4">已撤回</span>
              </div>
            </template>
            <template slot="createTime" slot-scope="scope">
              {{ scope.row.createTime | formatDate }}
            </template>
            <template slot="options" slot-scope="scope">
              <div class="data-operate">
                <span class="op-blue" @click="viewTasks(scope.row)">
                  查看
                </span>
                <span
                  v-show="scope.row.status === 0"
                  class="op-blue"
                  @click="goDetail('edit', scope.row)"
                >
                  修改
                </span>
                <span
                  v-show="scope.row.status === 0"
                  class="op-red"
                  @click="delById(scope.row.id)"
                >
                  撤销
                </span>
                <span
                  class="op-red"
                  v-show="scope.row.status === 2"
                  @click="placeOrder(scope.row.id)"
                >
                  下单
                </span>
              </div>
            </template>
          </pageTable>
        </div>
      </div>
      <addAcquisition
        v-if="dialogVisible"
        :dialogType="dialogType"
        :dialogVisible.sync="dialogVisible"
        :queryObject="childObject"
        @update="searchFn()"
      />
    </div>
    <el-dialog
      title="查看采集任务"
      :visible.sync="viewDialogVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <div class="view-dialog">
        <div v-if="task.remarks != null">
          <div class="form-lable">查看失败原因</div>
          <div class="view-border">{{ task.remarks ? task.remarks : '' }}</div>
        </div>
        <div class="form-lable">
          <div class="form-lable">基本信息</div>
          <el-descriptions class="margin-top" :column="1" border>
            <el-descriptions-item label="任务id">
              {{ task.id ? task.id : '' }}
            </el-descriptions-item>
            <el-descriptions-item label="任务名称">
              {{ task.taskName ? task.taskName : '' }}
            </el-descriptions-item>
            <el-descriptions-item label="机构名称">
              {{ task.agencyName ? task.agencyName : '' }}
            </el-descriptions-item>
            <el-descriptions-item label="监测主体">
              {{ task.grabType ? task.grabType : '' | grabTypeFilters }}
            </el-descriptions-item>
            <el-descriptions-item
              :label="task.grabType === 1 ? '公众号' : '网站链接'"
            >
              {{ task.agencyHomeUrl ? task.agencyHomeUrl : '' }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="form-lable">
          <div class="form-lable">采集范围</div>
          <el-table border :data="task.channelList" style="width: 100%">
            <el-table-column type="index" label="序号"> </el-table-column>
            <el-table-column prop="channelName" label="模块名称">
            </el-table-column>
            <el-table-column prop="channelUrl" label="网页地址" width="450">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import addAcquisition from '@/views/dataAcquisition/components/addAcquisition.vue';

export default {
  // 数据采集任务-运营人员下单
  name: 'dataAcquisition',
  components: {
    addAcquisition,
  },
  data() {
    return {
      selectData: [],
      loading: false,
      // 我的任务列表
      columnList: [
        {
          label: '序号',
          type: 'index',
          width: 50,
        },
        {
          label: '任务名称',
          prop: 'taskName',
        },
        {
          label: '机构名称',
          prop: 'agencyName',
        },
        {
          label: '监测主体',
          show: 'template',
          prop: 'grabType',
        },
        {
          label: '网址/公众号',
          prop: 'agencyHomeUrl',
        },
        {
          label: '采集状态',
          show: 'template',
          prop: 'status',
        },
        {
          label: '任务创建时间',
          show: 'template',
          prop: 'createTime',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: window.innerWidth >= 1920 ? '160' : '200',
        },
      ],
      searchParams: {
        agencyName: '', //机构名称
        grabType: '', //监测主体
        status: null, //采集状态
        ids: '',
      },
      searchParamsIds: '',
      grabTypes: [
        { label: '网站', value: 0 },
        { label: '公众号', value: 1 },
      ],
      orderTypes: [
        { label: '正式订单', value: '2000' },
        { label: '试用订单', value: '1000' },
      ],
      operationList: [],
      statuses: [
        { label: '待采集', value: 0 },
        { label: '采集中', value: 1 },
        { label: '已完成', value: 2 },
        { label: '已拒绝', value: 3 },
        { label: '已撤回', value: 4 },
      ],
      dialogVisible: false,
      childObject: {}, //子组件需要的值
      dialogType: 'add',
      viewDialogVisible: false,
      //查看任务
      task: {},
    };
  },
  filters: {
    grabTypeFilters: function (value) {
      let obj = {
        1: '网站',
        2: '公众号',
      };

      let name = obj[value] || '';
      return name;
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  methods: {
    getTaskIdsByCurrentUser() {
      this.$api.dataAcquisition.getTaskIdsByCurrentUser().then((res) => {
        if (res.code === 0) {
          this.searchParamsIds = res.data.toString();
          this.searchParams.ids = this.searchParamsIds;
          this.$nextTick(() => {
            this.searchFn('search');
          });
        }
      });
    },
    searchFn() {
      const tableRef = this.$refs.table;
      tableRef.getPageDataOfAction('search');
    },
    clearSearchFn() {
      this.searchParams = {
        agencyName: '', //机构名称
        grabType: '', //监测主体
        status: null, //采集状态
        ids: this.searchParamsIds,
      };
      this.$nextTick(() => {
        this.searchFn('search');
      });
    },
    // 新建修改任务
    goDetail(type, data) {
      switch (type) {
        case 'add':
          this.dialogType = 'add';
          break;
        case 'edit':
          this.dialogType = 'edit';
          break;
      }
      if (type === 'edit') {
        this.childObject = {
          id: data.id,
        };
      } else {
        this.childObject = {
          id: null,
        };
      }
      this.dialogVisible = true;
    },
    //查看
    viewTasks(row) {
      this.$api.dataAcquisition.queryById({ id: row.id }).then((res) => {
        if (res.code === 200) {
          this.task = res.data.task;
          this.viewDialogVisible = true;
        }
      });
    },
    // 撤销
    delById(id) {
      this.$msg.confirm('确认撤销？').then(() => {
        this.$api.dataAcquisition
          .delById({
            id: id,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('撤销成功');
              this.$refs.table.getPageDataBeforeDel();
            }
          });
      });
    },
    // 下单
    placeOrder(id) {
      var query = {
        id: id,
      };
      this.$router.push({
        path: '/newAddOrder',
        query,
      });
    },
    // 时间格式化
    formatDate(date) {
      let formatted_date =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds();
      return formatted_date;
    },
  },
  mounted() {
    this.getTaskIdsByCurrentUser();
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100%;
  overflow: auto;
}
.form-lable {
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  margin: 5px 0;
  color: #333;
}
.view-border {
  height: 50px;
  line-height: 50px;
  width: 100%;
  border: 1px solid #f56c6c;
  border-radius: 4px;
  background-color: rgb(255, 250, 250);
  color: #f56c6c;
  padding-left: 20px;
}
.view-dialog {
  padding: 0 50px;
  max-height: 25rem;
  overflow-y: auto;
}
::v-deep .el-dialog .el-dialog__body {
  padding: 0 10px 20px 10px;
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  width: 120px;
}
::v-deep .form-lable {
  line-height: 30px;
}
</style>
